




















import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'Accepted',
  created (): void {
    this.setAccepted()
  },
  methods: {
    ...mapActions('appointments', [
      'setAccepted'
    ])
  }
})
